import React from 'react';
import Button from './Button';
import Select from 'react-select';

const Input = ({
  type,
  label,
  name,
  description,
  className = '',
  onChange,
  reference,
  error,
  required = false,
  value = null,
  disabled = false,
  onKeyPress = null,
  menuPlacement = 'bottom',
  min = null,
  max = null,
  maxlength = null,
  hidePass = null,
  showHidePass = null,
  onFocus,
  onBlur,
  rows = 6,
  labelKeys = null,
  hideLabel = false,
  integratedLabel = false,
  loadOptions = null,
  onInputChange,
  placeholder,
  options = null,
  menuPosition,
}) => {
  let form = '';

  if (type === 'text' || type === 'password' || type === 'email' || type === 'number') {
    if (hidePass !== null && name === 'password') {
      className += ' with-show-hide';
    }

    if (integratedLabel === true) {
      className += ' with-integrated-label';
    }

    form = (
      <span className={error ? 'has-error' : undefined} style={{ position: 'relative' }}>
        {hideLabel === false && (
          <label htmlFor={name}>
            {label}
            {required === true && ' *'}
          </label>
        )}
        {integratedLabel === true && (
          <label htmlFor={name} className="integrated-label">
            {label}
            {required === true && ' *'}
          </label>
        )}
        <input
          value={value ? value : undefined}
          type={type}
          disabled={disabled}
          id={name}
          key={name}
          className={className}
          onChange={(e) => (onChange ? onChange(name, e.target.value) : undefined)}
          onFocus={(e) => (onFocus ? onFocus(name, e.target.value) : undefined)}
          onBlur={(e) => (onBlur ? onBlur(name, e.target.value) : undefined)}
          ref={reference ? reference : undefined}
          onKeyPress={onKeyPress ? onKeyPress : undefined}
          min={min}
          max={max}
          maxLength={maxlength}
        />
        {description && <div className="input-desc">{description}</div>}
        {hidePass !== null && (
          <Button className="show-password-button" link onChange={showHidePass}>
            {hidePass === true ? 'global.general.show' : 'global.general.hide'}
          </Button>
        )}
      </span>
    );
  } else if (type === 'textarea') {
    form = (
      <span className={error ? 'has-error' : undefined}>
        {hideLabel === false && (
          <label htmlFor={name}>
            {label}
            {required === true && ' *'}
          </label>
        )}
        <textarea
          type={type}
          value={value ? value : undefined}
          key={name}
          className={className}
          onChange={(e) => (onChange ? onChange(name, e.target.value) : undefined)}
          onFocus={(e) => (onFocus ? onFocus(name, e.target.value) : undefined)}
          onBlur={(e) => (onBlur ? onBlur(name, e.target.value) : undefined)}
          ref={reference ? reference : undefined}
          rows={rows}
          disabled={disabled}
        />
        {description && <div className="input-desc">{description}</div>}
      </span>
    );
  } else if (type === 'select') {
    form = (
      <span className={error ? 'has-error' : undefined}>
        {hideLabel === false && (
          <label htmlFor={name}>
            {label}
            {required === true && ' *'}
          </label>
        )}
        <Select
          name={name}
          key={name}
          className={'select-box ' + className}
          value={value ? value : undefined}
          ref={reference ? reference : undefined}
          placeholder={placeholder ? placeholder : undefined}
          onChange={(selected) => (onChange ? onChange(name, selected) : undefined)}
          onInputChange={(val) => (onInputChange ? onInputChange(val) : undefined)}
          options={options}
          isDisabled={disabled}
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          loadOptions={loadOptions}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {description && <div className="input-desc">{description}</div>}
      </span>
    );
  } else if (type === 'checkbox') {
    form = (
      <span>
        <label>
          <input
            type="checkbox"
            key={name}
            className={className}
            disabled={disabled}
            checked={value ? value : undefined}
            onChange={(e) => (onChange ? onChange(name, e.target.checked) : undefined)}
            ref={reference ? reference : undefined}
          />
          {label}
        </label>
      </span>
    );
  }

  return form;
};

export default Input;

import React, { useRef, useState } from 'react';
import Layout from '../components/layout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import Alert from '../components/Alert';
import Input from '../components/Input';
import Button from '../components/Button';

const ContactPage = () => {
  const yourName = useRef(null);
  const email = useRef(null);
  const message = useRef(null);
  const contactNumber = useRef(null);
  const swimmersName = useRef(null);
  const swimmersAge = useRef(null);

  const OPTIONS = [
    {
      value: 0,
      label: 'Blantyre Venue',
    },
    {
      value: 1,
      label: 'Holyrood Secondary',
    },
    {
      value: 2,
      label: "St Paul's High",
    },
  ];
  const [venue, setVenue] = useState(OPTIONS[0]);

  const [yourNameError, setYourNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [contactNumberError, setContactNumberError] = useState(false);
  const [swimmersNameError, setSwimmersNameError] = useState(false);
  const [swimmersAgeError, setSwimmersAgeError] = useState(false);
  const [addToWaitingList, setAddToWaitingList] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (val) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
  };

  const checkForm = (e) => {
    e.preventDefault();
    console.log('check logs');
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      checkForm(e);
    }
  };

  return (
    <Layout styles="contact" title="Get in Touch">
      <div className="contact-form">
        <form
          onSubmit={(e) => {
            checkForm(e);
          }}
          className="form-container"
        >
          {error !== null && <Alert type={3} message={error} />}
          {success !== null && <Alert type={1} message={success} />}
          <Input
            type="text"
            label="Name"
            key="yourName"
            name="yourName"
            className="yourName"
            required={true}
            error={yourNameError}
            reference={yourName}
            onKeyPress={handleEnter}
          />
          <Input
            type="text"
            key="SwimmersName"
            label="Swimmers Name"
            required={true}
            name="swimmersName"
            className="swimmersName"
            error={swimmersNameError}
            reference={swimmersName}
            onKeyPress={handleEnter}
          />
          <Input
            type="text"
            label="Contact Number"
            key="ContactNumber"
            name="contactNumber"
            className="contactNumber"
            error={contactNumberError}
            reference={contactNumber}
            onKeyPress={handleEnter}
          />
          <Input
            type="text"
            key="SwimmersAge"
            label="Swimmers Age"
            required={true}
            name="swimmersAge"
            className="swimmersAge"
            error={swimmersAgeError}
            reference={swimmersAge}
            onKeyPress={handleEnter}
          />

          <Input
            type="text"
            key="EmailAddress"
            label="Email Address"
            required={true}
            name="email"
            className="email"
            error={emailError}
            reference={email}
            onKeyPress={handleEnter}
          />
          <Input
            type="select"
            required={true}
            key="Venue"
            label="Venue"
            name="venue"
            className="venue"
            value={venue}
            onChange={(key, val) => setVenue(OPTIONS[val])}
            options={OPTIONS}
          />
          <Input
            type="textarea"
            key="Message"
            required={true}
            label="Message"
            name="message"
            className="message"
            error={messageError}
            reference={message}
            onKeyPress={handleEnter}
          />
          <Input
            key="waitingList"
            type="checkbox"
            name="addToWaitingList"
            label="Add Me to the Waiting List"
            value={addToWaitingList}
            onChange={(key, val) => setAddToWaitingList(val)}
          />

          <Button submit label="Send Message" color="blue" disabled={loading} />
        </form>
      </div>
      <div className="contact-opening">
        <h2>Opening Times</h2>
        <div className="contact-day-container">
          <div className="contact-day">Monday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            15:45 - 19:45
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Blantyre Venue
          </div>
          ---
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            17:00 - 21:00
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Holyrood Secondary (Ladies Only session)
          </div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Tuesday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            13:45 - 17:45
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Blantyre Venue
          </div>
          ---
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            17:00 - 20:00
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            St Paul's High
          </div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Wednesday</div>
          <div className="contact-time">Closed</div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Thursday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            16:15 - 18:15
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Holyrood Secondary
          </div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Friday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            17:00 - 20:00
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            St Paul's High (Ladies hour 18:50 - 19:40)
          </div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Saturday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            09:00 - 13:00
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Holyrood Secondary (Ladies Only Session)
          </div>
        </div>
        <div className="contact-day-container">
          <div className="contact-day">Sunday</div>
          <div className="contact-time">
            <FontAwesomeIcon icon={faClock} />
            13:00 - 16:00
          </div>
          <div className="contact-location">
            <FontAwesomeIcon icon={faLocationDot} />
            Holyrood Secondary
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

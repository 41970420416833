import React from 'react';
const Alert = ({ type, message, style, title = null, alertKey, callback, callbackLabel = 'Close' }) => {
  switch (type) {
    case 1:
      type = 'success';
      break;
    case 2:
      type = 'warning';
      break;
    case 3:
      type = 'error';
      break;
    default:
      type = 'general';
  }

  return (
    <div className={`alert alert-${type} ${title !== null ? 'alert-titled' : ''}`} style={style}>
      {title === null ? (
        message
      ) : (
        <div>
          <div className="alert-header">
            <div className="alert-title">{title}</div>
            {callback !== undefined && (
              <div className="alert-close" onClick={() => callback(alertKey)}>
                {callbackLabel}
              </div>
            )}
          </div>
          <div className="alert-message">{message}</div>
        </div>
      )}
    </div>
  );
};

export default Alert;
